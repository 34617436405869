import {initialState as auth} from "../reducers/auth";
import {initialState as utils} from "../reducers/utils";
import {initialState as languages} from "../reducers/languages";
import {initialState as moderators} from "../reducers/moderators";
import {initialState as staticTexts} from "../reducers/staticTexts";
import {initialState as media} from "../reducers/media";
import {initialState as composite} from "../reducers/composite";
import {initialState as category} from "../reducers/category";
import {initialState as blog} from "../reducers/blog";
import {initialState as charity} from "../reducers/charity";
import {initialState as project} from "../reducers/project";
import {initialState as general} from "../reducers/general";
import {initialState as subscribers} from "../reducers/subscribers";
import {initialState as about} from "../reducers/about";
import {initialState as companies} from "../reducers/companies";
import {initialState as slider} from "../reducers/slider";
import {initialState as partner} from "../reducers/partner";


const reducers = {
    auth,
    utils,
    languages,
    moderators,
    staticTexts,
    media,
    composite,
    category,
    blog,
    charity,
    project,
    general,
    subscribers,
    about,
    companies,
    slider,
    partner
};

export default state => {
    const sData = {};
    Object.keys(reducers).forEach(selectorKey => {
        Object.keys(reducers[selectorKey]).forEach(key => {
            sData[key] = state[selectorKey][key];
        });
    });
    return sData;
};

