export const ACCESS_PAGES = {
    dashboard: {
        key: 'SLIDER',
        name: 'Սլայդեր',
        icon: 'amp_stories',
        crud: true,
        isHidden: false,
    },
    moderators: {
        key: 'MODERATORS',
        name: 'Մոդերատորներ',
        icon: 'supervised_user_circle',
        crud: false,
        isHidden: false,
    },
    blog: {
        key: 'NEWS',
        name: 'Նորություններ',
        icon: 'import_contacts',
        crud: true,
        isHidden: false,
    },
    charity: {
        key: 'CHARITIES',
        name: 'Բարեգործություններ',
        icon: 'description',
        crud: true,
        isHidden: false,
    },
    partners: {
        key: 'PARTNERS',
        name: 'Գործընկերներ',
        icon: 'group_work',
        crud: true,
        isHidden: false,
    },
    media: {
        key: 'FILES',
        name: 'Ֆայլեր',
        icon: 'filter',
        crud: true,
        isHidden: false,
    },
    companies: {
        key: 'COMPANIES',
        name: 'Ընկերություններ',
        icon: 'apartment',
        crud: true,
        isHidden: false,
    },
    about: {
        key: 'ABOUT',
        name: 'Մեր Մասին',
        icon: 'category',
        crud: true,
        isHidden: false,
    },
    subscribers: {
        key: 'SUBSCRIBERS',
        name: 'Բաժանորդներ',
        icon: 'supervisor_account',
        crud: true,
        isHidden: false,
    },
    languages: {
        key: 'LANGUAGES',
        name: 'Լեզուներ',
        icon: 'language',
        crud: true,
        isHidden: false,
    },
    contact: {
        key: 'CONTACTS',
        name: 'Կապ',
        icon: 'contact_phone',
        crud: true,
        isHidden: false,
    },
    staticTexts: {
        key: 'STATIC_TEXTS',
        name: 'Թարգմանություններ',
        icon: 'text_format',
        crud: true,
        isHidden: false,
    },
};
